.defaulttop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar {
  background: #fff;
  padding: 12px;
  border-bottom: 1px solid #e1e6f1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(225, 230, 241);
  box-shadow: -7.829px 11.607px 20px 0px #908f8f1a;
  transition: 0.3s ease;
}

.small {
  width: auto;
  margin-left: 180px;
}

.topbar-fixed {
  position: fixed;
  width: calc(100% - 70px);
}

.topbar-menu {
  position: relative;
  margin-bottom: 70px;
  z-index: 10;
  width: 100%;
}

.topbar .search {
  position: relative;
}

.topbar .profile {
  position: relative;
}

.topbar .profile strong {
  font-size: 13px;
  color: #444444;
}

.topbar .profile button {
  border-radius: 100px;
  margin-right: 10px;
  font-weight: bold;
  text-decoration: none;
  background: none;
  border: none;
  color: #444444;
}

.topbar .profile .menuProfile {
  position: absolute;
  top: 35px;
  left: 0;
  background: #fff;
  width: 100%;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  padding: 10px;
  z-index: 1000;
}

.topbar .search_mobile {
  display: none;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.topbar .search_mobile input {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 100px;
  border: none;
  padding: 10px 15px;
  width: 100%;
}

.topbar .search input {
  min-width: 250px;
  border-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.topbar .search input.searchLoad {
  background-size: 25px 25px;
  background-position: 10px 50%;
}
.topbar .search .search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 10px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #333;
}

.topbar .search .search-bar .advancedSearchIcon {
  font-size: 15px;
  padding: 6px 12px 6px 10px;
  background: #6259ca;
  border: 1px #6259ca solid;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.topSearchResult {
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  max-height: 250px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.75) 16px 20px 36px 2px;
}
.topSearchResult ul {
  list-style: none;
}

.topSearchResult ul li {
  border-bottom: 1px solid #999;
}

.topSearchResult ul li .linkicon {
  text-decoration: none;
  display: block;
  padding: 10px;
  cursor: pointer;
}

.topSearchResult ul li .linkicon:hover {
  background: #e5f1ff;
}

.topSearchResult ul li span {
  display: block;
  font-size: 10px;
}

.topSearchResult ul li:last-child {
  border-bottom: none;
}

.topbar .actions {
  margin-right: 10px;
  display: flex;
}

.topbar .actions .action-menu {
  margin-left: 15px;
}

.topbar .actions .action-menu .MuiAvatar-root:hover {
  cursor: pointer;
}

.notifications {
  text-align: center;
  font-size: 12px;
}

.logout-wrapper {
  display: flex;
  position: absolute;
  background: #fff;
  width: 180px;
  height: 60px;
  z-index: 1;
  top: 65px;
  right: 0;
  box-shadow: 10px 10px 20px 2px #cccccc;
  padding: 25px 25px 25px 25px;
  justify-content: center;
  align-content: center;
}

.sair {
  margin-top: -10px;
  color: #444444;
  font-weight: bold;
}

.logout-icon {
  margin-left: 10px;
  color: #444444;
  margin-top: -5px;
}

.logout {
  margin-top: -10px;
  cursor: pointer;
}

.username {
  margin-right: 15px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

.hamburguer {
  font-size: 25px;
  margin-left: 13px;
  margin-top: 5px;
}

.blueWord {
  color: #444444;
}

.topbar .current-company {
  position: absolute;
}

.text-modal {
  padding: 10px 0px 10px 0px;
}

.color-validation {
  border-color: #f44336;
  background-color: #ffcdd2;
}
