@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import '../../../node_modules/video-react/dist/video-react.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

button {
  line-height: 1.538;
  padding: 7px 20px;
  border-radius: 4px;
  transition: none;
  min-height: 38px;
  display: inline-block;
  font-weight: 400;
  color: #8f9cc0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.175rem 2.25rem 0.175rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d577d;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e8e8f7;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 38px;
  border-radius: 5px;
}

body,
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #eaedf7;
  color: #4f4f4f;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
}
*:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.mainwrapper {
  height: 100%;
  min-height: 100%;
  display: flex;
}

.menu {
  background: #333;
  overflow: hidden;
  width: 300px;
  transition: 0.3s;
}

.menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu nav ul li {
  width: 300px;
  padding: 25px 0;
  transition: 0.1s;
}

.menu nav ul li:hover:not(:first-child) {
  background: rgb(28, 106, 250);
  /* padding: 20px 50px 20px 10px; */
  /* border-radius: 100px; */
  cursor: pointer;
}

.menu nav ul li:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.menu nav ul li:first-child button img {
  margin-left: 7px;
  margin-right: 30px;
}

.menu nav ul li a,
.menu nav ul li button {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
  background: none;
  border: none;
  outline: 0;
}

.menu nav ul li a img,
.menu nav ul li button img {
  margin-right: 20px;
}

.menu #showMenu {
  display: none;
  font-size: 14px;
  padding-left: 26px;
}

.minMenu {
  width: 67px;
}

.content {
  width: 100%;
}

.smaller {
  width: calc(100% - 190px);
  margin-left: 180px;
  transition: 0.3s ease;
}

.no-wrap {
  flex: 0 0 auto;
}

.panels {
  padding: 15px 20px;
  width: auto;
  transition: 0.3s ease;
}

.panels__kanban {
  width: fit-content;
  min-width: 100%;
}

.painel {
  margin-bottom: 15px;
  position: relative;
}

.painel-content {
  background: #fff;
  border-radius: 11px;
  min-height: 100%;
  padding: 10px;
}

.painel .painel-title {
  padding: 8px 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(28, 39, 60);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

.painel .painel-title.center {
  display: block !important;
  text-align: center;
}

.painel .painel-subtitle {
  border-bottom: 0.5px solid #d8d8d8;
  padding: 15px 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.painel .painel-subtitle strong {
  color: #444444;
  margin-right: 8px;
}

.painel .painel-title .panel-search {
  border: none;
  background: #fff url('../img/search-icon.png') 3% center no-repeat;
  padding-left: 40px;
  width: 300px;
}

.painel .painel-title a {
  background: #6259ca;
  color: #fff !important;
  border-radius: 10px;
  padding: 10px;
}

.painel .painel-title a[role='button'] {
  padding: 6px 16px;
  border-radius: 4px;
  color: #fff;
}

.painel .painel-title a:hover {
  color: #574cca;
  background: #fff !important;
  text-decoration: none;
  color: #999 !important;
}

.painel .painel-title a[role='button']:hover {
  padding: 6px 16px;
  border-radius: 4px;
  background: #534ca4 !important;
  color: white !important;
  border: none;
}

.painel .painel-body {
  /*min-height: 150px;*/
  position: relative;
  padding: 5px 20px;
}

.painel .painel-fitheight {
  min-height: auto !important;
}

.painel .painel-nopaddingtb {
  padding: 0 !important;
}

.painel .pg-pesquisa .nav-link {
  background: #fff !important;
  color: #4f4f4f;
  border: none;
  border-bottom: 0.5px solid #d8d8d8;
  padding: 7px 20px;
  text-align: center;
  flex: 1;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.painel .pg-pesquisa .nav-link.active {
  background: #6259ca !important;
  color: #fff;
}

.painel .pg-pesquisa .nav-tabs {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.painel .painel-body.overflow {
  overflow-y: auto;
  max-height: 250px !important;
}

.loading {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  top: 0;
  padding-top: 50px;
}

.dropzone {
  border: 2px dashed #dcd9f5;
  border-radius: 3px;
  background-color: #f2f1ff;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.85;
  cursor: pointer;
  outline: none;
  min-height: 150px;
  background-image: url('../img/fancy_upload.png');
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e8e8f7;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.tableContainer {
  position: relative;
}

.tableContainer .tableLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
}

.tableLoading .loadingContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.pagination li {
  display: block;
  float: left;
  margin-right: 10px;
}
.pagination .active a,
.pagination li a:hover {
  color: #0062cc;
  background: #fff;
  border: 1px solid #0069d9;
  padding: 4px 9px;
}
.pagination li a {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  background: #0069d9;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

/*
.painel .painel-body table thead tr th {
  border-top: none;
}

.painel .painel-body table thead tr th {
  border-bottom: none;
}
*/

.painel .painel-body table thead tr th {
  font-weight: bolder;
}

.painel .painel-footer {
  border-top: 0.5px solid #d8d8d8;
  padding: 15px 20px;
  font-weight: bold;
}

.painel .painel-footer button {
  border: none;
  background: none;
  color: #444444;
}
.painel .painel-footer button svg {
  margin-left: 8px;
}

.clear {
  clear: both;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.modal-title {
  font-size: 16px;
  font-weight: 700;
  color: #4a526f;
  line-height: 1;
  margin-bottom: 0;
  line-height: 1.5;
  padding: 10px;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8e8f7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: center;
  padding: 0px;
}

form .form-label {
  font-size: 11px;
  color: #a8afc7;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

form .row {
  margin-bottom: 10px;
}

form .buttons {
  margin-top: 40px;
}

form .buttons button {
  width: 120px;
}

form .buttons .send {
  background: #444444;
  border-radius: 10px !important;
  margin-right: 20px;
}

form .buttons .reset {
  background: #fff;
  color: #444444;
  border: 1px solid #444444;
  border-radius: 10px !important;
}

.left-column {
  border-right: 1px solid #d8d8d8;
}

.right-column {
  padding-left: 20px;
}

@media (max-width: 800px) {
  /* .menu {
    display: none;
  }

  .menu nav ul li {
    width: 100%;
  } */

  .topbar .search {
    display: none;
  }

  .topbar .search_mobile {
    display: flex;
  }

  .topSearchResult {
    top: 43px;
  }

  .topbar .search_mobile button {
    margin-left: 20px;
    background: none;
    border: none;
    font-size: 30px;
  }
}

.status-active,
.status-inactive {
  padding: 5px 20px;
  color: #fff;
  border-radius: 8px;
}

.status-active {
  background-color: #19b159;
}

.status-active:hover {
  background-color: #37ce4f;
}

.status-inactive {
  background-color: #989998;
}

.status-inactive:hover {
  background-color: #676867;
}

.cards-wrapper {
  padding: 50px 70px 50px 70px;
}

.card-red-content {
  margin-top: 15px;
  background: #ed213a;
  background: -webkit-linear-gradient(to bottom, #93291e, #ed213a);
  background: linear-gradient(to bottom, #93291e, #ed213a);
  width: 250px;
  height: 200px;
  -webkit-box-shadow: 0 0 10px 2px #c7c7c7;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-green-content {
  margin-top: 15px;
  background: #348f50;
  background: -webkit-linear-gradient(to top, #56b4d3, #348f50);
  background: linear-gradient(to top, #56b4d3, #348f50);

  width: 250px;
  height: 200px;
  -webkit-box-shadow: 0 0 10px 2px #c7c7c7;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-yellow-content {
  margin-top: 15px;
  background: #f2994a;
  background: -webkit-linear-gradient(to top, #f2c94c, #f2994a);
  background: linear-gradient(to top, #f2c94c, #f2994a);

  width: 250px;
  height: 200px;
  -webkit-box-shadow: 0 0 10px 2px #c7c7c7;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-default-content {
  margin-top: 15px;
  background: #373b44;
  background: -webkit-linear-gradient(to top, #4286f4, #373b44);
  background: linear-gradient(to top, #4286f4, #373b44);

  width: 250px;
  height: 200px;
  -webkit-box-shadow: 0 0 10px 2px #c7c7c7;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-divida-content {
  margin-top: 15px;
  background: #e0e0e0;
  width: 250px;
  height: 200px;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 10px 2px #c7c7c7;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
  border: 0.5px solid #333;
}

.card-divida-description {
  color: #333;
  font-size: 25px;
  margin-top: 100px;
  position: absolute;
}

.card-divida-value {
  color: #333;
  font-size: 65px;
  margin-top: 30px;
  position: absolute;
}

.card-divida-bg-icon {
  width: 130px;
  z-index: -1;
  opacity: 0.2;
  margin-top: 20px;
  margin-left: 120px;
}

.card-description {
  color: #fff;
  font-size: 25px;
  margin-top: 100px;
  position: absolute;
}

.card-value {
  color: #fff;
  font-size: 65px;
  margin-top: 30px;
  position: absolute;
}

.card-bg-icon {
  font-size: 130px;
  color: #fff;
  z-index: -1;
  opacity: 0.2;
  margin-top: 20px;
  margin-left: 120px;
}

.alignMiddle {
  display: flex;
  width: 100%;
  flex-shrink: 0;
}

.controlpanel-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.controlpanel-item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.controlpanel-item .btn {
  min-width: 150px;
  border-radius: 4px;
  transition: none;
  min-height: 34px;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #6259ca;
  user-select: none;
  padding: 1px 10px;
  font-size: 0.875rem;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlpanel-item .btn-fit {
  width: auto !important;
}

.bg-white {
  background-color: #fff !important;
}

.badge {
  font-weight: 500;
  padding: 4px 6px 5px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge.bg-secondary {
  background-color: #f1388b !important;
  color: #fff;
}

.badge.bg-success {
  color: #ffffff !important;
  background-color: #19b159 !important;
}

.MuiAutocomplete-root {
  width: 100%;
}

.button-container {
  margin-top: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.button-container button {
  margin-left: 15px;
}
.MuiTab-wrapper {
  font-family: 'Inter', sans-serif !important;
  font-weight: bolder;
  text-transform: inherit !important;
  font-size: 14px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.modal-padding {
  padding: 8px;
}

.MuiTable-root {
  border: none !important;
}

.MuiTableCell-root {
  font-size: 1.09rem !important;
  font-size: 12px !important;
  border: none !important;
}

.flex-direction {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkicon {
  margin-right: 7px;
}

.flex-direction a {
  margin-right: 7px;
}

.flex-direction a:last-child {
  margin-right: 0;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #6259ca !important;
}

.MuiTableSortLabel-icon {
  display: none !important;
}

.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  display: block !important;
}

.MuiAppBar-colorPrimary {
  background: #fff !important;
  color: #7f7f7f !important;
}

.MuiTab-fullWidth {
  /*border-right: 1px solid #fff !important;*/
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
  transition: background-color 200ms;
}
.MuiTab-textColorInherit:hover {
  opacity: 1 !important;
  background: #e6e4f6 !important;
  color: #0e0e23 !important;
}
.MuiTab-textColorInherit.Mui-selected {
  opacity: 1 !important;
  background: #6259ca !important;
  color: #c8c6e5 !important;
}
.MuiTab-wrapper {
  font-size: 14px !important;
  display: inline-flex;
}

.MuiAlert-root {
  color: #856404 !important;
  background-color: #ffeeba !important;
  border-color: #ffeeba !important;
  border-radius: 8px !important;
}

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #856404 !important;
}

.shadow-tab {
  -webkit-box-shadow: 0 6px 4px -9px black;
  -moz-box-shadow: 0 6px 4px -9px black;
  box-shadow: 0 6px 4px -9px black;
  margin-bottom: 6px;
}

.MuiTab-wrapper {
  text-transform: capitalize !important;
  display: block !important;
}

.MuiTab-labelIcon {
  min-height: 48px !important;
}

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-right: 10px;
}

.MuiTypography-body1 {
  font-family: 'Inter', sans-serif !important;
}

.MuiSelect-select.MuiSelect-select {
  font-family: 'Inter', sans-serif !important;
}

.MuiButton-label {
  font-family: 'Inter', sans-serif !important;
}

.normal-letter-spacing {
  letter-spacing: normal;
}

.border-top {
  border-top: 1px solid #e8e8f7;
}

a {
  color: #333 !important;
}

:root {
  --primary-bg-color: #6259ca;
  --primary-bg-hover: #403fad;
  --primary-transparentcolor: rgba(98, 89, 202, 0.16);
  --primary-bg-border: #6259ca;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --light-gb: #eeeeee;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
