.mailbox-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 16px;
}

.mailbox-container .mailbox-pastas svg {
  margin-right: 8px;
}

.mailbox-container .mailbox-pastas .mailbox-pasta {
  border-radius: 0 20px 20px 0;
  width: calc(100% - 16px);
}

.mailbox-container .mailbox-pastas .Mui-selected {
  background-color: #7f7f7f !important;
  color: #fff;
}

@media (max-width: 800px) {
  .mailbox-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
