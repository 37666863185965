.view__pass {
  font-size: 16px;
  border-radius: 10px;
  position: relative;
  top: -25px;
  display: inline-block;
  left: 430px;
  cursor: pointer;
}

.painel-body-grahps,
.painel-body-progress {
  min-height: 300px;
}

.painel-body-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
