.action-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced4da;
}

.action-item:last-child {
  border: none;
}

.action-item button {
  padding: 8px 15px !important;
}
