.thumbnail-container {
  position: relative;
}

.thumbnail-container .thumbnail-hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-container .thumbnail-hover svg {
  display: none;
  transition: display 200ms;
}

.thumbnail-container .thumbnail-hover:hover {
  background-color: #00000045;
}

.thumbnail-container .thumbnail-hover:hover svg {
  display: initial;
}

.thumbnail-container img {
  width: 118px;
  height: 168px;
  cursor: pointer;
}

.thumbnail-container .preview-image {
  max-width: 100%;
  max-height: 100%;
}