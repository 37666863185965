
.main-table-wrapper {
    position: absolute;
    width: 1354px;
    max-height: 60vh;
    left: 400px;
    top: 20%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.table-wrapper {
    width: 95.5%;
    overflow: hidden;
    position: relative;
    left: 2%;
    
}

.table-wrapper thead th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: #4F4F4F;
    font-size: 16px;
    text-align: center;
    border-top: 2px solid #E5E5E5;;
    letter-spacing: 0.03em;
}

.table-wrapper tbody td {
    text-align: center;
    border: 1px solid #E5E5E5;
    border-left: none;
    font-size: 14px;
    letter-spacing: 0.03em;
}

.table-wrapper tr:hover {
    background-color: #f7f7f7;
}

/* tr:nth-child(even) {
    background-color: #f7f7f7;
} */

.main-table-wrapper .search-icon {
    position: absolute;
    left: 50px;
    top: 18px;
    height: 20px;
    width: 20px;
}

.main-table-wrapper .search-input {
    position: relative;
    font-size: 16px;
    left: 90px;
    top: 15px;
    margin-bottom: 25px;
    width: 350px;
    border: none;
    letter-spacing: 0.03em;
    color: #4F4F4F;
}

.main-table-wrapper .search-input::placeholder {
    color: #E5E5E5;
}
.linkicon{
    color:black;

}
.linkicon:hover{
    color:black
}