* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.selectImage input[type="file"] {
  display: none;
}

.selectImage button {
  width: 100%;
  padding: 60px 0;
  min-height: 500px;
  border: none;
  background: #fff;
  border: 1px solid #77a2ff;
  color: #999;
  cursor: pointer;
}

.tpImagemDesc {
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  padding: 10px 0 5px;
}

.tpImagemDesc label {
  font-weight: bolder;
}

.tpImagemDesc span {
  margin-left: 10px;
}

.viewer {
  position: relative;
  overflow: auto;
  text-align: center;
}

.btOtherDocument {
  margin: 20px 0;
}

.viewer .pdfViewerButtons nav {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer .pdfViewerButtons nav button {
  background-color: #444444;
  border: none;
  color: white;
  padding: 5px 10px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 25px;
  margin-left: 20px;
}

.viewer .pdfViewerButtons nav .disabled {
  background-color: #666 !important;
}

.viewer .pdfTotalPages {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(68, 152, 247);
}

.search-table thead tr th,
.search-table tbody tr td {
  font-size: 12px !important;
}

.search-table tbody tr {
  cursor: pointer;
}

.indexador {
  margin-bottom: 10px;
}

.indexador strong {
  width: 100%;
  display: block;
  color: #444444;
}

.panel-title-blue {
  background: #444444 !important;
  color: #fff !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
