* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.main-wrapper {
  background: var(--light-gb);
  height: 100vh;
}

.main-wrapper .form-empresas-wrapper {
  position: absolute;
  width: 80vw;
  max-height: 80vh;
  height: 800px;
  left: 335px;
  top: 18.5%;
  background: #fff;
  border-radius: 50px;
}

.register-user-title {
  position: relative;
  color: #4f4f4f;
  font-family: "Product Sans";
  font-size: 30px;
  font-weight: bold;
  top: 22px;
  left: 54px;
  letter-spacing: 0.03em;
}

.left-info .user-header-info {
  position: absolute;
  width: 325px;
  height: 18px;
  left: 50px;
  top: 90px;

  font-family: "Product Sans";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: #444444;
}

/* START MEDIA QUERIE */

@media (min-width: 1300px) and (max-width: 1900px) {
  .register-user-title {
    font-size: 25px;
  }

  .left-info .user-header-info {
    width: 150px;
    font-size: 18px;
    top: 65px;
  }

  .left-info .username-wrapper .user-item {
    font-size: 15px;
    top: 155px;
  }

  .left-info .username-wrapper .user-info {
    font-size: 15px;
    top: 178px;
  }

  .left-info .username-wrapper .line-divisor {
    top: 205px;
  }

  .left-info .user-login .user-item {
    font-size: 15px;
    top: 125px;
  }

  .left-info .user-login .user-info {
    font-size: 15px;
    top: 150px;
  }

  .left-info .user-login .line-divisor {
    top: 178px;
  }

  .left-info .user-email .user-item {
    top: 93px;
  }

  .left-info .user-email .user-info {
    top: 115px;
  }

  .left-info .user-email .line-divisor {
    top: 145px;
  }

  .left-info .user-pass-exp .user-item {
    top: 60px;
  }

  .left-info .user-pass-exp .user-info {
    top: 83px;
  }

  .left-info .user-pass-exp .line-divisor {
    top: 115px;
  }

  .left-info .user-department .user-header-info {
    font-size: 15px;
    top: -70px;
  }

  .register-user-wrapper {
    width: auto;
  }

  .register-user-wrapper .right-info .side-divisor {
    width: 400px;
    left: 270px;
    top: 260px;
  }

  .register-user-wrapper .right-info .user-options-info {
    font-size: 18px;
    top: 65px;
    right: 160px;
  }

  .register-user-wrapper .right-info .options-inputs-admin {
    font-size: 15px;
    right: 465px;
    top: 110px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-ip {
    font-size: 15px;
    right: 205px;
    top: 110px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-ip .input-title {
    right: 20px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-hour {
    font-size: 15px;
    top: 110px;
    right: 35px;
  }

  .register-user-wrapper
    .right-info
    .options-inputs-restrict-hour
    .input-title {
    right: 15px;
  }

  .register-user-wrapper .right-info .checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .register-user-wrapper .right-info .checkbox-label .checkbox-custom {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #0080fc;
  }

  .register-user-wrapper
    .right-info
    .checkbox-label
    input:checked
    ~ .checkbox-custom {
    background-color: #0080fc;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .register-user-wrapper .right-info .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009bff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .register-user-wrapper
    .right-info
    .checkbox-label
    input:checked
    ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  .register-user-wrapper .right-info select {
    width: 50px;
  }
}

/* END MEDIA QUERIES */

.left-info .username-wrapper {
  position: absolute;
  top: -55px;
}

.left-info .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  left: 50px;
  top: 200px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.left-info .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  left: 50px;
  top: 230px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.left-info .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 400px;
  left: 50px;
  top: 260px;
}

.left-info .user-login {
  position: absolute;
  top: 40px;
}

.left-info .user-login .line-divisor {
  width: 200px;
}

.left-info .user-login .user-pass .line-divisor {
  width: 150px;
}

.left-info .user-login .user-pass {
  position: absolute;
  left: 250px;
}

.left-info .user-login .user-email {
  position: absolute;
  top: 100px;
}

.left-info .user-login .user-email .line-divisor {
  width: 400px;
}

.left-info .user-login .user-pass-exp {
  position: absolute;
  top: 200px;
}

.left-info .user-login .user-pass-exp .user-item {
  width: 300px;
}

.left-info .user-login .user-pass-exp .line-divisor {
  width: 400px;
}

.left-info .user-login .user-department {
  position: absolute;
  top: 400px;
}

.left-info .user-login .user-department .department-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 120px;
  left: 50px;
  width: 400px;
}

.left-info .user-login .user-role {
  position: absolute;
  top: 500px;
}

.left-info .user-login .user-role .department-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 120px;
  left: 50px;
  width: 400px;
}

select {
  background: #fff;
  height: 45px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #d8d8d8;
  color: #4f4f4f;
  font-weight: bold;
}

.right-info .side-divisor {
  position: absolute;
  width: 600px;

  height: 0px;
  left: 300px;
  top: 400px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  transform: rotate(90deg);
}

.right-info .user-options-info {
  position: absolute;
  width: 325px;
  height: 18px;
  right: 400px;
  top: 90px;

  font-family: "Product Sans";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: #444444;
}

.right-info .options-inputs-admin {
  position: absolute;
  right: 699px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
}

.right-info .options-inputs-admin .input-title {
  position: absolute;
  top: 3px;
  left: 40px;
}

.right-info .options-inputs-restrict-ip {
  position: absolute;
  right: 350px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
  width: 300px;
}

.right-info .options-inputs-restrict-ip .input-title {
  position: absolute;
  top: 3px;
  right: 5px;
}

.right-info .options-inputs-restrict-hour {
  position: absolute;
  right: 100px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
  width: 300px;
}

.right-info .options-inputs-restrict-hour .input-title {
  position: absolute;
  top: 3px;
  right: 0;
}

.right-info .checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.right-info .checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.right-info .checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #0080fc;
}

.right-info .checkbox-label input:checked ~ .checkbox-custom {
  background-color: #0080fc;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
}

.right-info .checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.right-info .checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.right-info .status-wrapper {
  position: absolute;
  top: -40px;
  right: 0;
}
.right-info .status-wrapper .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 250px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .status-wrapper .status-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 280px;
  right: 100px;
  width: 622px;
}

.right-info .status-wrapper .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 250px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .status-wrapper .status-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 280px;
  right: 100px;
  width: 622px;
}

.right-info .phone-wrapper {
  position: absolute;
  right: 0;
  top: -50px;
}

.right-info .phone-wrapper .cel-item .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .phone-wrapper .cel-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 580px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .phone-wrapper .cel-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 473px;
  top: 430px;
}

.right-info .phone-wrapper .phone-item .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 300px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .phone-wrapper .phone-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 206px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .phone-wrapper .phone-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 100px;
  top: 430px;
}

.right-info .hour-wrapper {
  position: absolute;
  top: 70px;
  right: 0;
}

.right-info .hour-wrapper .hour-init-item .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  right: 570px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .hour-wrapper .hour-init-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 580px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .hour-wrapper .hour-init-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 473px;
  top: 430px;
}

.right-info .hour-wrapper .hour-finish-item .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  right: 198px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .hour-wrapper .hour-finish-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 206px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .hour-wrapper .hour-finish-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 100px;
  top: 430px;
}

.right-info .actions-wrapper {
  position: absolute;
  right: 100px;
  top: 580px;
}

.right-info .actions-wrapper .action-send {
  position: absolute;
  top: -20px;
  right: 0;
}

.right-info .actions-wrapper .send-btn {
  width: 620px;
  height: 50px;
  text-transform: uppercase;
  font-size: 17px;
  font-family: "Product Sans";
  color: #fff;
  background: #0080fc;
  border-radius: 4px;
  animation: none;
  border: none;
  letter-spacing: 0.05em;
}

.right-info .actions-wrapper .send-btn:hover {
  cursor: pointer;
  background: #009bff;
  transition: 0.3s;
  border: 1px solid #444444;
}

.right-info .actions-wrapper .action-reset {
  position: absolute;
  top: 40px;
  right: 0;
}

.right-info .actions-wrapper .empty-btn {
  width: 620px;
  height: 50px;
  text-transform: uppercase;
  font-size: 17px;
  font-family: "Product Sans";
  color: #4f4f4f;
  background: #d8d8d8;
  border-radius: 4px;
  animation: none;
  border: none;
  letter-spacing: 0.05em;
}

.right-info .actions-wrapper .empty-btn:hover {
  cursor: pointer;
  background: rgb(190, 189, 189);
  transition: 0.3s;
  border: 1px solid rgb(177, 177, 177);
}

.linkicon {
  color: #212529;
}
.linkicon:hover {
  color: #212529;
}
