.dados-auditados-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dados-auditados-content span {
  width: 100%;
  padding: 8px 0;
}

.dados-auditados-content .header {
  color: #444444;
  font-weight: bold;
}

.dados-auditados-content .captalize {
  text-transform: capitalize;
}

.dados-auditados-content .italico {
  font-style: italic;
}

.dados-auditados-content .pl-16 {
  padding-left: 16px;
}

.dados-auditados-content .pl-32 {
  padding-left: 32px;
}

.dados-auditados-cap {
  text-transform: capitalize;
}