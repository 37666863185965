.bpmviewer {
  max-height: 450px;
  width: 100%;
  padding: 20px 0;
  margin-top: -50px
}

.xml-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  z-index: 1000;
}