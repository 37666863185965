.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input {
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.white-loading svg {
    color: #ffff;
}

.esteira-avancar-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
}

.esteira-avancar-table th {
    background-color: #6259CA;
    border: 1px solid #ddd;
    color: #fff
}

.esteira-avancar-table td,
.esteira-avancar-table th {
    padding: 10px
}