.form-indexacao-container {
  max-height: calc(100% - 25px);
  padding-top: 8px;
  overflow: auto;
  overflow-x: hidden;
}

.form-indexacao-container .separador {
  padding: 8px;
  background: #333;
  color: #fff;
  margin-bottom: 8px;
}
