.list-wrapper {
    position: absolute;
    top: 100px;
    width: 650px;
    height: 200px;
}


.section-list-items li {
    position: relative;
    width: 660px;
    height: 18px;
    padding-top: 25px;
    padding-bottom: 25px;

    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    font-weight: bold;
    color: #4F4F4F;
}

.section-list-items .line-divisor {
    background: #D8D8D8;
    height: 2px;
    top: 15px;
}

.section-list-items li.active {
    padding-left: 22px;
    margin-bottom: 10px;
    width: 650px;
    right: 20px;
    height: 10px;
    background: rgba(196, 196, 196, 0.1);
    background-position-y: 5%;
    border-radius: 8px;
    color: #2e8cfa;
    transition: 0.3s;
}