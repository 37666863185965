.createdAt-user {
  display: block;
  font-size: 10px;
}

.badge {
  padding: 4px 6px 5px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-green {
  background-color: #19b159 !important;
  color: #fff;
}

.badge-red {
  background-color: #f16d75 !important;
  color: #fff;
}
