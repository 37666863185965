.indexadorTable {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  font-size: 13px;
  text-align: center;
  padding: 5px;
}
.indexadorTable tr td,
.indexadorTable tr th {
  border: 1px solid #ddd;
}

.indexadorTableNoContent {
  text-align: center;
}
