.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  width: 100%;
}
.login-form .left,
.login-form .center,
.login-form .right {
  width: 33%;
  min-height: 30%;
}

.login-form .left {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.login-form .center {
  background: #999;
  color: #fff;
  height: 70%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form .right {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.login-form .right .content,
.login-form .left .content {
  background: #6aaefc;
  height: 30%;
}

.login-form .left .content {
  border-top-left-radius: 100%;
  text-align: center;
}

.login-form .center .content .loginLogo {
  text-align: center;
}

.login-form .right .content {
  border-bottom-right-radius: 100%;
}

.login-form .loginfields {
  width: 60%;
  margin: 0 auto;
}

.login-form .loginfields input {
  width: 100%;
  border: none;
  padding: 10px;
  margin-top: 20px;
  padding-left: 20px;
  color: #000;
}

.login-form .loginfields button {
  width: 100%;
  background: #fff;
  color: #444444;
  border: none;
  padding: 10px 0;
  margin-top: 20px;
  border-radius: 10px;
}

.login-form .formlinks {
  margin-top: 30px;
}

.login-form .formlinks a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px;
  width: 100%;
}

.login-form .formlinks a span {
  font-weight: bolder;
}

.login-form .formlinks a:first-child {
  text-align: right;
}



.login-form .password .color-validation {
  border-color: #f44336;
  background-color: #ffcdd2;
}

.loginError {
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-top: 12px;
}

@media (max-width: 800px) {
  .login-form .center {
    width: 100%;
  }
  .login-form .right,
  .login-form .left {
    display: none;
  }
}

.recaptcha {
  margin-top: 15px;
}

.error__captcha {
  color: #fff;
  background: rgb(252, 8, 8);
  padding: 10px;
  border-radius: 30px;
}

.forgot__pass {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.forgot-password-spacing {
  padding: 30px 0px 0px 0px;
  font-size: 25px;
}
