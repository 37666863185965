.image-viewer {
  height: 100%;
  min-height: 70vh;
  width: 100%;
}

.image--viewer__viewer {
  position: relative;
  overflow: auto;
  text-align: center;
  width: 100%;
}

.pdfviewer {
  height: 100%;
  width: 100%;
}
.viewer-toolbar-current-page-input {
  width: 25px !important;
}
.viewer-layout-toolbar {
  font-size: 9px !important;
}

.viewer-toolbar-right div:nth-of-type(2),
.viewer-toolbar-right div:nth-of-type(3),
.viewer-toolbar-right div:nth-of-type(4) {
  display: none !important;
}

.bottomThumbViewer {
  font-size: 12px;
  font-weight: bold;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomThumbViewer span {
  color: #999;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomThumbViewer span svg {
  font-size: 16px;
}
