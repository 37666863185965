.controlpanel-item-filter {
    width: 100%;
    align-items: center;
    justify-content: start;
    margin-bottom: 0;
    padding-bottom: 0;

    span {
        margin-left: 20px;
    }
}

.painel-filters-viewr {
    margin: 22px;
}
