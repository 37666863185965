.esteiraSearchResult {
  text-align: center;
}

.esteira-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.button-esteira {
  background-color: #0c4383 !important;
  padding: 8px 10px 8px 10px !important;
  min-width: 32px !important;
}
