.indexadores {
  padding: 15px;
}

.indexadores .accordion {
  border-right: 1px solid rgb(216, 216, 216);
  border-left: 1px solid rgb(216, 216, 216);
  border-bottom: 1px solid rgb(216, 216, 216);
}

.indexadores .accordion .accordion-title {
  border: 1px solid rgb(216, 216, 216);
  background: #0c4383;
  color: #fff;
  min-height: 36px;
}

.indexadores .accordion .accordion-title > div {
  padding: 0;
  margin: 0;
}

.indexadores .accordion .accordion-title span {
  color: #fff;
}

.indexadores h4 {
  margin: 20px 0 10px 0;
  border-bottom: 1px solid #999;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bolder;
}

.indexadores .indexador strong {
  font-size: 12px;
  color: #6259ca;
}
.separador strong {
  font-size: 12px;
  color: #a8afc7;
  margin-bottom: 1.2rem;
}
.indexadores .indexador span {
  font-size: 12px;
}

.indexadores-actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.dados-section {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.dados-section .header-section {
  margin-bottom: 20px;
}

.dados-section .header-section svg {
  margin-top: -4px;
  margin-right: 5px;
}

.dados-section .indexador {
  margin-bottom: 20px;
}

.noPadding {
  padding: 0;
}

.indexadores-viewer {
  border: 1px solid #e8e8f7;
  border-radius: 11px;
}

.indexadores-section {
  padding: 1.5rem;
}

.indexadores-section-header label {
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  font-size: 13px;
}
