.mergepdf-container {
    position: fixed;
    right: 0;
    top: 80px;
}

@media(max-width: 840px) {
    .mergepdf-container {
        position: fixed;
        right: 0;
        top: 160px;
    }
}