.classificacao-imagens {
  max-height: 100%;
  overflow: auto;
}

.classificacao-imagens .drag-imagens {
  border: 1px dashed rgb(126, 122, 122);
  padding: 8px;
}

.classificacao-imagens .drag-imagens p {
  text-align: center;
}

.classificacao-imagens .item-classificacao {
  padding: 8px 0px;
  display: flex;
  align-items: center;
}

.classificacao-imagens .item-classificacao .item-detalhes {
  padding: 0px 8px;
  width: calc(100% - 163px);
}

.classificacao-imagens .item-classificacao .item-detalhes .mark-all {
  font-weight: bold;
}

.classificacao-imagens .item-classificacao .item-detalhes p {
  margin-bottom: 4px;
}