.itemChecklistName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formalizacao-forms {
  overflow-x: hidden;
  max-height: 400px;
}

.itemChecklistName {
  text-wrap: wrap !important;
}

.itemChecklistName svg {
  margin-left: 5px;
}

.formalizacao-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.formalizacao-actions button {
  margin-left: 15px;
}

.formalizacao-motivos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formalizacao-motivos span {
  margin-top: 4px;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1 !important;
  background: #fff !important;
  color: #000 !important;
}

.scrollableTabs {
  overflow-x: 'hidden';
}

.checklist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px
}

.checklist-header-search {
  display: flex;
  align-items: center;
  width: 80%;
}

.checklist-header-search .MuiFormControl-root {
  margin-right: 10px;
}

.checklist-header-search fieldset .MuiFormGroup-root {
  width: 300px;
}

.checklist-header-actions {
  width: auto;
}


.checklist-header .MuiFormControlLabel-root {
  margin-right: 10px;
}

/* Se a tela for menor que 600px */
@media (max-width: 1200px) {
  .checklist-header-search .MuiTextField-root {
    width: 180px;
  }

  .checklist-header-search fieldset .MuiFormGroup-root {
    width: 300px;
  }
}