.color{
    color:red
}

.buscar-tipo-pessoa {
    margin-top: 28px
}

.color-validation {
    border-color: #F44336;
    background-color: #FFCDD2;

    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Empresas--form__horario-funcionamento {
    display: flex;    

    
}

.Empresas--form__horario-funcionamento .MuiFormControl-root:not(:last-of-type) {
    margin-right: 32px;
}

.Empresas--form__horario-funcionamento .MuiFormControl-root input {
    width: 35px;
    margin-left: auto;
}