.pdf-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  font-size: 13px;
}

.left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;
}

.canvas {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 50px;
}


.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 50%;
}

.paginationButtons button {
  margin-left: 10px;
}

.header-modal {
  border-bottom: 1px solid #dee2e6;
}

.signature {
  position: absolute;
  width: 140px;
  height: 30px;
  background: #6259ca;
  border: 2px solid #6259ca;
  opacity: 0.5;
  font-size: 12px;
  color: #fff;
  padding: 5px;
}

.signature-info {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 1rem;
  border-radius: 0.5rem;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.sign-container {
  background-color: #fff;
  margin-bottom: 15px;
}

.sign-container span {
  border-bottom: 1px solid #c3c3c3;
  display: block;
  padding: 5px 15px;
  margin-bottom: 7px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 0px; 
  padding: 2px 15px 2px 15px;
}
