
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.line-components {
    display: flex;
    align-items: center;
}

.line-components label{
    margin-right: 12px;
}