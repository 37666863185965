.panel {
  margin-bottom: 20px;
}
.comments-form {
  margin-top: 20px;
}

.index-title {
  background: #7d75e1;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.index-title.formalizacao {
  background: #7d75e1;
  padding: 15px;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
}

.index-title button {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 10px;
}

.index-title span {
  color: #fff;
  font-weight: bold;
}

.index-inputs {
  background: #fff;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}

.index-inputs .row {
  margin-bottom: 0;
}

.index-inputs .index__content {
  top: 0;
  margin-bottom: 20px;
}

.index-inputs span {
  font-weight: bold;
}

.index-name {
  color: #444444;
  margin-top: 15px;
}

.index-obg {
  color: #f00 !important;
}

.index__input {
  margin-top: 9px;
  /* max-width: 40%; */
  resize: horizontal;
}

.index__content {
  position: relative;
  top: -17px;
}

.item-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-check svg {
  cursor: pointer;
}

.preview-docs-title {
  background: #fff;
  padding: 0;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8d8d8;
}

.preview-docs-total {
  font-size: 10px;
}

.preview-docs-total strong {
  color: #444444 !important;
}

.author-comment {
  margin-top: 10px;
}

.comment-full-line {
  margin-top: -20px;
}

@media (max-width: 600px) {
  .preview-docs-title {
    margin-top: 10px;
  }

  .preview-doc-image {
    max-width: 420px;
  }
}

.preview-docs-title span {
  color: rgb(49, 49, 49);
  font-weight: bold;
}

.preview-doc {
  min-height: 250px;
  border: 0.5px solid #d8d8d8;
  border-top: none;
  background: #fff;
}

.preview-doc-image {
  max-width: calc(100% - 100px);
}

.checklist-inputs {
  background: #fff;
}

.checklist-inputs span {
  font-weight: bold;
}

.checklist-tasks {
  margin-top: 10px;
  color: #444444;
  font-weight: bold;
  padding: 0px 8px;
}

.checklist-tasks .row {
  margin-bottom: 20px;
}

.checklist-comment {
  background: #fff;
  padding: 30px 0px;
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
}

.checklist-commnet-container {
  padding: 0 30px;
}

.checklist-comment button {
  font-weight: bold;
  /* color: rgb(49, 49, 49); */
}

.checklist-comment-area textarea {
  resize: none;
  height: 80px;
  background: rgba(229, 229, 229, 0.1);
}

.checklist-attach-icon {
  position: absolute;
  left: 48%;
  margin-top: -20px;
  z-index: 1;
}

.checklist-attach-icon:hover {
  cursor: pointer;
}

.historico-title {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -51.5px;
  right: 0;
  margin-right: 15px;
  width: 50%;
  cursor: pointer;
}

.historico-title-active {
  background: #444444;
  color: #fff;
  position: absolute;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -51.5px;
  right: 0;
  margin-right: 15px;
  width: 50%;
  cursor: pointer;
}

.historico-title span {
  font-weight: bold;
}

.historico-title-active span {
  font-weight: bold;
}

.historico-infos {
  background: #fff;
  min-height: 150px;
  padding: 30px;
  position: relative;
  border: 0.5px solid #d8d8d8;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
}

.historico-infos small {
  margin-left: 40px;
}

.last-comments {
  background: #fff;
  max-height: fit-content;
  padding: 30px 0;
  position: relative;
}

.last-comments small {
  margin-top: 10px;
  margin-left: 40px;
}

.name-commentor {
  color: #444444;
  font-weight: bold;
  margin-left: 10px;
}

.comment-line {
  background: rgba(29, 133, 255, 0.19);
  padding: 5px;
  margin-left: 40px;
  margin-top: 10px;
  width: 90%;
  border-radius: 5px;
}

.comment-full {
  margin-left: 10px;
}

.check-combo {
  margin-top: 10px;
  margin-left: 15px;
  width: 40%;
}

.checklist-buttons button {
  margin-right: 20px;
}

.checklist-buttons a:hover {
  text-decoration: none;
}

.MuiButton-label {
  padding: 4px 2px !important;
}

.Mui-disabled > .MuiButton-label {
  color: #000;
  padding: 4px 2px !important;
}

.MuiInputBase-root > .Mui-disabled {
  color: rgba(0, 0, 0, 0.78);
}

.Mui-disabled > .MuiButton-label {
  color: #000;
  padding: 4px 2px !important;
}
.index-inputs .row {
  margin-bottom: 10px;
}
