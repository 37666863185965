.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input{
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.progress-bar {
    width: 80%;
    display: flex;
    margin-right: 32px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.white-loading svg {
    color: #ffff;
    width: 30%;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone p {
    color:#58504F 
}

.dropzone-list{
    padding: 20px 0px 20px 0px;
}

.dropzone-list svg {
    cursor: pointer;
}