* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

.painel-title-controls {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: '100%';
}

.painel-title-controls strong{
    margin-right: 24px;
}
