
#zoom {
  display: flex;
}

#zoom img {
  width: 100%;
  object-fit: cover;
}


