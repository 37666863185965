.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.btn-back-type-document {
  margin-top: 5px;
}