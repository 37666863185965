.menu-search {
  background: #fff;
  border-radius: 11px;
}

.menu-search-header {
  border-bottom: 1px solid #eaedf7;
  padding: 20px 25px 7px 25px;
}

.menu-search h4 {
  font-size: 15px;
  line-height: 1.2;
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

.menu-search-content {
  padding: 25px 10px;
}

.menu-search .nav-link {
  color: #606c8c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 38px;
  margin-bottom: 0.5rem;
}

.menu-search .nav-link.active,
.menu-search .nav-link:hover {
  color: #6259ca;
  font-weight: 600;
}

.menu-search .nav-link svg {
  color: #606c8c;
  font-size: 18px;
  line-height: 0;
  width: 24px;
  margin-right: 10px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  line-height: 0.9;
}

.menu-search-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-search-list li {
  padding: 0;
  margin: 0;
  list-style: none;
}
