#searchViewer {
  width: 100%;
  height: 100%;
}

#viewer {
  width: 100%;
}

.imageGroup {
  margin-bottom: 20px;
}
.imageGroup b {
  display: block;
  background-color: #444444;
  color: #fff;
  padding: 5px 10px;
}
.thumbViewer {
  display: block;
  margin-top: 10px;
  text-align: center;
  min-height: 300px;
  padding: 10px;
}

.thumbViewer .thumbViewerContainer {
  transition: box-shadow 0.3s;
  background: #e6e6e6;
  padding: 10px 5px 14px 5px;
  height: 100%;
}

.thumbViewer .topThumbViewer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: -7px;
}

.thumbViewer .thumbViewerContainer:hover {
  -webkit-box-shadow: 8px 6px 11px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 6px 11px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 6px 11px -5px rgba(0, 0, 0, 0.75);
}

.thumbViewer .bodyThumbViewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.thumbViewer .bodyThumbViewer .description {
  position: absolute;
  transform: rotate(-40deg);
  color: #444444;
  font-weight: bolder;
  font-size: 18px;
  margin-left: 0px;
}

.thumbViewer img,
.thumbViewer svg,
.thumbViewer .description {
  cursor: pointer;
}

.thumbViewer img,
.thumbViewer svg {
  opacity: 0.8;
  width: 100%;
}

.viewerControlPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.viewerControlPanel button {
  margin-left: 10px;
}

.search-viewer__show-document--navigation {
  padding: 10px !important;
  padding-top: 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pg-pesquisa *:focus {
  outline: none;
}

.tabLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.search-viewer__thumbnail-gallery--container {
  min-height: 500px;
  overflow-y: auto;
}

.search-viewer__thumbnail-gallery--wrapper {
  max-height: 600px;
  min-height: 300px;
  padding: 15px 0;
}

.textFound {
  background: #8fa6c6 !important;
}

.search-viewer__file-info {
  margin: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #555;
}

.search-viewer__file-info span {
  color: #999;
}
