.task-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 10px 15px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  border-radius: 11px;
  margin: 15px 0;
  background-color: #fff;
  cursor: pointer;
}

.task-list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.task-list-item__header_title {
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
  font-size: 13px;
}

.task-list-item__header_subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.task-list-item__header_subtitle_item {
  margin-right: 22px;
  width: 100%;
}

.task-list-item__header__label {
  font-weight: 600;
  color: #6259ca;
  margin-right: 5px;
}

.task-list-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.task-list-item__content__participants_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.task-list-item__content__participants_status label {
  padding: 0px 10px 0px 0px;
  font-size: 12px;
  font-weight: 500;
}

.task-list-item__content__participants_status_item {
  flex: 1;
}

.task-list-item__content__participants {
  display: flex;
  align-items: center;
}

.task-list-item__content__status {
  text-align: right;
}
