.loading-application {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgb(28, 106, 250);
}
.loading-application img {
  margin-bottom: 40px;
  display: block;
  margin: 0 auto;
}
.loading-application .dados {
  text-align: center;
  color: #fff;
}

.loading-application .dados h4 {
  margin-top: 30px;
}
