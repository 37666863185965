.field {
  margin-top: 15px;
}

.MuiTextField-root {
  width: 100%;
}

.docTypeFields {
  margin-top: 20px;
}

.docTypeFields .separator {
  padding: 8px;
  background: #333;
  color: #fff;
}

.docTypeFields .MuiDivider-root {
  margin-bottom: 10px;
}

.esteira-indexador {
  margin-bottom: 20px;
}

.search-form-buttons button {
  margin-right: 10px;
  width: 100px;
}

.grupo-title-autocomplete {
  cursor: pointer;
  padding: 0;
  padding: -10px;
  color: gray;
  padding: 16px 8px;
  font-size: 12px;
}

.grupo-title-autocomplete:hover {
  background: rgba(228, 228, 228, 0.603);
}
