* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.main-wrapper {
  background: var(--light-gb);
  height: 100vh;
}

.header-buttons {
  width: 400px;
  text-align: right;
}

.header-buttons a {
  margin-left: 10px;
}

.hidden {
  visibility: hidden;
}

.btnDownloadReport {
  background: #444444 !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 10px !important;
}
