h5 {
    font-weight: bolder;
}

.painel-content-formulario {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px !important;
}
.wire-title, 
.wire-line ,
.wire-block,
.form-user
{
    display: flex;
    align-items: center;
}

.wire-title {
    width: 100%;
    flex-direction: row;
}

.wire-title,
.wire-line {
    justify-content: space-between;
}

.button-refresh {
    border: 2px solid rgba(0, 0, 0, 0.09);
}

.button-add {
    width: 85px;
    height: 85px;
}

.button-preview {
    border: 2px solid rgba(0, 0, 0, 0.09);
}

.wire-block #simple-select {
    width: 80px;
    color: #3c4858;
}
.drag-n-drop-item,
.drag-n-drop-item-invisivel,
.title-items {
    background-color: white;
    display: flex;
    flex-Direction: 'row';
    justify-content: 'space-around';
    align-items: center;
    padding: 12px;
    width: 100%;
    margin: 8px 4px;
}

.drag-n-drop-item {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.09);
    box-shadow:  2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.drag-n-drop-item-invisivel {
    font-style: italic;
    border-radius: 12px;
    color:gray;
    background-color: rgb(226, 226, 226);
}

.title-items {
    font-weight: bold;
}

.painel-body-preview {
    width: 100%;
    padding: 12px 36px;
    margin-top: 8px;
}

.painel-body-preview-item {
    margin-bottom: 8px;
}

.painel-body-message {
    width: 100%;
    min-height: 468px;
    padding: 12px 36px;
    margin: 8px 0;

    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-user {
    width: 100%;
    justify-content: center;
    background-color: #f3f3f3;
}

.form-user h3 {
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.form-user input:disabled {
    background-color: rgb(228, 228, 228);
    color: rgb(87, 87, 87);
}

/* --------------- */
/* --------------- */

.wizard {
    border: 1px solid #e8e8f7;
    background-color: #ffffff;
}

/* STEPS **********************/

@media (min-width: 992px) {
    .wizard.vertical>.steps {
        padding: 25px;
        width: 40%;
    }

    .wizard>.steps {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps {
        width: 20%;
        float: left;
    }

    .wizard>.steps {
        padding: 25px;
    }
}

.wizard.vertical>.steps {
    padding: 20px;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    background-color: #6259ca;
    color: #fff;
    margin-right: 8px;
    border-radius: 3px;
    width: 30px;
    height: 30px;
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    margin-right: 8px;
    flex-shrink: 0;
    font-weight: 700;
    background-color: #f0f0ff;
    color: #8f9cc0;
    display: block;
    text-align: center;
    line-height: 2;
    width: 30px;
    height: 30px;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps a {
    color: #a8afc7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps span {
    color: #a8afc7;
    font-size: 14px !important;
}

.wizard>.steps .current span {
    color: #8f9cc0;
}

.wizard>.steps .passed span {
    color: black;
}

.wizard>.steps .passed a .number,
.wizard>.steps .passed a:hover .number,
.wizard>.steps .passed a:active .number 
{
    background-color: #3c4858a9;
    border-radius: 50%;
    color:#f0f0ff;
}

.wizard li {
    list-style-type: none !important;
    display: flex;
    color: #6259ca;
}

/* CONTENT **********************/

@media (min-width: 992px) {
    .wizard.vertical>.content {
        width: 60%;
        padding: 25px;
    }

    .wizard>.content {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 70%;
        float: right;
        border-left: 1px solid #e8e8f7;
    }

    .wizard>.content {
        padding: 25px;
    }
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px;
}

.wizard>.content {
    border-top: 1px solid #e8e8f7;
    border-bottom: 1px solid #e8e8f7;
    min-height: 150px;
    padding: 20px;
}

.wizard .actions .nav-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
/**********************/

