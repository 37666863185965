.mensagem-container .mensagem-header {
  display: flex;
  align-items: center;
  padding: 8px;
}

.mensagem-container .mensagem-header h6 {
  font-weight: bold;
}

.mensagem-container .mensagem-body {
  padding: 24px;
}

.mensagem-container .mensagem-body .mensagem-body-header {
  display: flex;
  justify-content: space-between;
}

.mensagem-container
  .mensagem-body
  .mensagem-body-header
  .mensagens-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
  height: fit-content;
  cursor: pointer;
}

.mensagem-container .mensagem-body .mensagem-body-header .mensagem-avatar {
  display: flex;
  align-items: center;
}

.mensagem-container .mensagem-body .mensagem-body-header .mensagem-avatar div {
  margin-right: 8px;
  background-color: #333;
}

.mensagem-container .mensagem-body > p {
  margin: 24px 0px;
}

.mensagem-container .mensagem-body .mensagem-anexos {
  font-weight: bold;
  display: flex;
}

.mensagem-container .mensagem-body .mensagem-anexos .mensagem-anexo {
  padding: 4px 16px;
  background-color: #c7c7c745;
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.mensagem-container .mensagem-body .mensagem-anexos .mensagem-anexo > div {
  margin-right: 8px;
  color: #fff;
  padding: 0px 4px;
  border-radius: 4px;
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-pdf {
  background-color: red;
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-png {
  background-color: rgb(9, 156, 9);
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-jpeg {
  background-color: rgb(18, 6, 175);
}
