.row-sm {
    margin-left: 0px;
    margin-right: 0px;
    background-color: transparent;
}

.row-sm>div {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.large-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: '100%' !important;
}
.custom-card-container {
    width: 400px;
    display: flex;
    flex-direction: row;
}
.custom-card {
    cursor: pointer;
    border: 0;
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 20px;
    box-shadow: -7.829px 11.607px 20px 0px rgba(183, 182, 190, 0.178);
}

.custom-card-header {
    background: #ffffff;
    display: flex;
    line-height: 0;
}

.card-sub-title {
    font-size: 13px;
    margin-top: 3px;
}

.title-cards {
    margin-left: 12px !important;
    font-size: 12px !important;
    padding-bottom: 8px;
    color: var(--gray);
}

.tx-12 {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.d-flex {
    display: flex !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.text-muted {
    color: #8f8fb1 !important;
}

.text-success {
    color: #19b159 !important;
}

.text-danger {
    color: #f16d75 !important;
}

.me-2 {
    margin-left: 0.3rem !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
    background-color: transparent !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
}

.main-content-label {
    font-weight: 500;
    color: #3c4858;
    font-size: 14px;
    margin-bottom: 0rem;
    line-height: 1;
}

.card-body {
    flex: 1 1 auto;
    padding: 25px;
}
.select-results {
    width: 320px;
    display: flex;
    align-items: center;
}

.select-results>label {
    margin-right: 8px;
}

.select-results #simple-select {
    width: 120px;
    color: #3c4858;
}