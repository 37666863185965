.select-files-to-upload-content .drag-imagens {
  border: 1px dashed rgb(126, 122, 122);
  padding: 8px;
}

.select-files-to-upload-content .item-upload {
  margin-top: 8px;
  padding: 8px 8px;
  display: grid;
  grid-template-columns: 125px 1fr;
  border: 1px solid #444444;
}

.select-files-to-upload-content .item-upload .item-details {
  display: flex;
  align-items: center;
}
