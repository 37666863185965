
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.loading-edit-tpdoc {
    display: flex;
    justify-content: center;
    margin-top: 10%;
}