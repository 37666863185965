:root {
  --menu-inactive: #eaedf771;
  --menu-active: #eaedf7;
  --primary-bg-color: #6259ca;
  --primary-bg-hover: #403fad;
  --primary-transparentcolor: rgba(98, 89, 202, 0.16);
  --primary-bg-border: #6259ca;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
}

.menu {
  width: auto;
  min-width: 80px;
  position: relative;
  height: 100%;
  min-height: 100%;
}

.menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  z-index: 10;
}

.menu-container,
.menu-click,
.menu-wrapped-click {
  background-color: #7f7f7f;
  width: 80px;
  min-width: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  transition: 0.1s;
  overflow: hidden;
  transition: 0.3s ease;
  min-height: 100%;
}

.menu-container svg {
  color: #fff;
}

.menu-container .menu-toolbar {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  height: 65px;
  background-color: var(--dark-theme);
}

.menu-footer {
  position: absolute;
  bottom: 0;
}

.menu-container .menu-toolbar button {
  outline: none;
}

.menu-container .menu-toolbar img,
.menu-wrapped .menu-toolbar img {
  width: 50px;
  height: auto;
}

.menu-wrapped .menu-toolbar img {
  display: inherit;
}

.menu-container .menu-header,
.menu-wrapped .menu-header {
  height: 140px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.menu-wrapped .menu-header {
  min-height: 0px;
  height: 0px;
}

.menu-wrapped .menu-header span,
.menu-wrapped .menu-header strong {
  display: inherit;
}

.menu-wrapped .menu-header span,
.menu-wrapped .menu-header strong {
  display: none;
}

.menu-container .menu-header span,
.menu-wrapped .menu-header span {
  font-size: 10px;
  color: darkgray;
}

.menu-container .menu-header .avatar,
.menu-wrapped .menu-header .avatar {
  height: 80px;
  width: 80px;
  background-color: var(--dark-theme);
  border-radius: 50%;
  position: absolute;
  bottom: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrapped .menu-header .avatar {
  height: 40px;
  width: 40px;
}

.menu-container .menu-header .avatar img,
.menu-wrapped .menu-header .avatar img {
  height: 72px;
  width: 72px;
}

.menu-wrapped .menu-header .avatar img {
  height: 32px;
  width: 32px;
}

.menu-container .menu-content {
  display: flex;
  flex: 1;
  background-color: var(--dark-theme);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
  padding-top: 5px;
}

.menu-container .menu-content strong {
  font-size: 11px;
  color: darkgray;
}

.menu-container .menu-content span {
  font-size: 12px;
}

.menu-container .menu-content ul {
  padding: 16px 0 0 0;
}

.menu-container .menu-content ul li {
  padding: 8px 12px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 0 16px 16px 0;
  transition: background-color 0.5s;
  width: calc(100% - 16px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-container .menu-content ul li a {
  text-decoration: none;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

.menu-container .menu-content ul li span {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.menu-container .menu-content ul li svg,
.menu-wrapped .menu-content ul li svg {
  margin-right: 8px;
}

.menu-wrapped .menu-content ul li svg {
  margin-right: 24px;
}

.menu-container .menu-content .submenu,
.menu-wrapped .menu-content .submenu {
  display: inherit;
  background-color: rgb(17, 62, 114);
}

.menu-wrapped .menu-content .submenu {
  display: none;
}

.menu-container .menu-content .submenu li {
  padding: 8px 0px 8px 32px;
  list-style: none;
  min-height: 37px;
}

.menu-container .menu-content ul .selected {
  background-color: #7f7f7f;
}

.menu-container .menu-content ul li:hover {
  background-color: rgb(0, 41, 88);
}

.menu-container .menu-content ul .selected:hover {
  background-color: #3496eb;
}

.menu-content button .MuiIconButton-label {
  width: 35px !important;
  height: 35px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 35px;
  text-align: left;
  margin-left: -20px;

  .active {
    margin-left: -13px !important;
    padding: 6px;
    margin: 1px;
    width: 35px !important;
    height: 35px;
    background: var(--primary-bg-color);
    color: var(--menu-active);
    border-radius: 50%;
    font-size: 9px;
    line-height: 35px;
    text-align: left;
  }
}

.menu,
.menu-fixed,
.menu-content,
.menu-wrapped,
.menu-container,
.menu-wrapped-click {
  .active {
    width: 260px !important;
    transition: max-width 0.3s ease-in-out;

    .menu-content button .MuiIconButton-label {
      margin-left: -198px !important;
    }
  }
}

.search-bar {
  .active {
    margin-left: +190px !important;
  }
}

.menu-content.active button .MuiIconButton-label {
  background: var(--primary-bg-color);
}

.menu-content button {
  width: 100%;
}

.menu-lateral {
  transition: 0.3s ease;
  margin-top: 0.6rem;
  padding-left: 15px;
  position: relative;
}

/* ======================================================= */
/*
.menu-lateral .active .shape1 {
  position: absolute;
  top: -30px;
  width: 20px;
  height: 60px;
  right: 0px;
  background-color: var(--menu-active);
}

.menu-lateral .active .shape2 {
  position: absolute;
  top: 35px;
  width: 20px;
  height: 30px;
  right: 0px;
  background-color: var(--menu-active);
}

.menu-lateral button:before,
.menu-lateral button:after {
  border-right: 21px solid var(--dark-theme);
  display: block !important;
}

.menu-lateral button:before {
  content: '';
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 0px 48px 0px;
}

.menu-lateral button:after {
  content: '';
  position: absolute;
  top: 41px;
  z-index: 0;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 48px 0px 0px;
} 
*/
/* ======================================================= */

.menu-item-wrapper {
  transition: max-height 0.4s ease-in-out;
  overflow: 'hidden';
}

.menu-lateral:hover {
  color: #333 !important;
}

.menu-lateral-item {
  background-color: var(--dark-theme) !important;
  padding: 3px !important;
  border-radius: 30px 0 0 30px !important;
  margin-bottom: 38px;
}

.menu-lateral-item.active {
  background-color: var(--menu-active) !important;
  transition: 0.3s;
}

.menu-lateral button svg {
  color: var(--menu-inactive) !important;
}

.menu-lateral:hover button svg {
  color: #fff !important;
}

.menu-item svg {
  color: var(--menu-inactive);
  margin-right: 24px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 14px;
  margin-left: 24px;
  color: var(--menu-inactive);
}

.menu-item :hover {
  color: #fff;
  transition: 0.25s;
}

.MuiMenuItem-root {
  border-bottom: 1px solid #ddd !important;
}

.MuiMenuItem-root a {
  width: 100%;
  height: 100%;
}
