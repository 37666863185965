.tarefas-container .tarefas-nao-lida {
  background-color: #bbbbbb33;
}

.tarefas-container .tarefas-aviso {
  width: 100%;
  height: calc(100vh - 195px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarefas-container .tarefas-actions {
  padding-left: 24px;
}

.tarefas-container .tarefas-list {
  height: calc(100vh - 195px);
  overflow: scroll;
}

.tarefas-container .tarefas-list .tarefas-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-header-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-header-details
  svg {
  margin-right: 8px;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-header h6 {
  font-weight: bold;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-avatar {
  display: flex;
  align-items: center;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-avatar
  div {
  margin-right: 8px;
  background-color: #333;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-body {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-body
  .tarefas-titulo-nao-lido {
  font-weight: bold;
  color: #7f7f7f;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-footer
  .tarefas-item-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
}

@media (max-width: 800px) {
  .tarefas-container .tarefas-list {
    height: calc(100vh - 350px);
    overflow: scroll;
  }
}
