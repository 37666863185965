.ops-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
}
.ops-container div {
  width: 100%;
  text-align: center;
}

.ops-container div a {
  margin-top: 20px;
  margin-right: 0 !important;
}
