.tarefa-container .tarefa-header {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
}

.tarefa-container .tarefa-header .tarefa-aprovar-reprovar {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-header .tarefa-title {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-header .tarefa-title h6 {
  font-weight: bold;
}

.tarefa-container .tarefa-body {
  padding: 24px;
}

.tarefa-container .tarefa-body .tarefa-body-header {
  display: flex;
  justify-content: space-between;
}

.tarefa-container .tarefa-body .tarefa-body-header .mensagens-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
  height: fit-content;
  cursor: pointer;
}

.tarefa-container .tarefa-body .tarefa-body-header .tarefa-avatar {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-body .tarefa-body-header .tarefa-avatar div {
  margin-right: 8px;
  background-color: #333;
}

.tarefa-container .tarefa-body > p {
  margin: 24px 0px;
}

.tarefa-container .tarefa-body .tarefa-anexos {
  font-weight: bold;
  display: flex;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo {
  padding: 4px 16px;
  background-color: #c7c7c745;
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo > div {
  margin-right: 8px;
  color: #fff;
  padding: 0px 4px;
  border-radius: 4px;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-pdf {
  background-color: red;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-png {
  background-color: rgb(9, 156, 9);
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-jpeg {
  background-color: rgb(18, 6, 175);
}
