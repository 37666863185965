.merge-process-container {
    background-color: #000000;
    color: #FFF;
    opacity: 0.8;
    margin-bottom: 8px;
    border-radius: 10px 0 0 10px;
}

.merge-process-container .merge-process-content {
    padding: 8px;
    display: flex;
    justify-content: center;
}

.merge-process-container .merge-process-content span {
    margin-left: 8px;
}

.merge-process-container .merge-process-progress {
    position: absolute;
    width: 96%;
    right: 0px;
    bottom: 8px;
}