.dialog-preview-images .navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

#modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8e8f7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: center;
  padding: 0px;
}
