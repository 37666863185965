#login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#login .content {
  width: 60%;
  height: 80%;
  min-height: 300px;
  background: #000;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 30px;
  box-shadow: 2px 2px 5px black;
}

#login .content .introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}

#login .content .login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px;
}

#login .content .login-content img {
  display: none;
}

#login .content .login-content .login-title {
  width: 100%;
  margin-bottom: 15px;
}

#login .content .login-content .loginForm {
  width: 100%;
}

#login .content .login-content .loginForm form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#login .content .login-content .loginForm form input {
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #999;
  width: 100%;
}

#login .content .login-content .loginForm button {
  background: #040e21;
  color: #fff;
  width: 60%;
  padding: 8px 0;
  border: none;
  border-radius: 5px;
  margin: 10px 0 15px 0;
}

.loginError {
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-top: 12px;
}

#login .login-password {
  width: 100%;
  display: flex;
}

#login .login-password input {
  padding: 23px 5px !important;
}

#login .login-password button {
  width: 40px !important;
  height: 20px !important;
}

/* twoFactor =============================================== */
#twofactor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#twofactor .content {
  width: 60%;
  height: 80%;
  min-height: 300px;
  background: #000;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 30px;
  box-shadow: 2px 2px 5px black;
}

#twofactor .content .introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}

#twofactor .content .twofactor-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px;
}

#twofactor .content .twofactor-content img {
  display: none;
}

.twofactor-title {
  width: 100%;
  margin-bottom: 15px;
}

.twofactorForm {
  width: 100%;
  text-align: center;
}

.twofactor-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.twofactor-form input {
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  height: 50px;
  font-weight: bold !important;
  border: 1px solid #999;
  color: #040e21;
}

@media (max-width: 800px) {
  #login .content {
    background: none;
    box-shadow: none;
  }

  #login .content .introduction {
    display: none;
  }

  #login .content .login-content {
    width: 100%;
    box-shadow: 2px 2px 5px black;
    background: #040e21;
    color: #fff;
  }

  #login .content .login-content img {
    display: block;
    margin: 15px 0;
  }

  #login .content .login-title {
    display: none;
  }

  #login .content .login-content .loginForm form input {
    background: #040e21;
    color: #fff;
  }

  #login .content .login-content .loginForm button {
    background: #fff;
    color: #040e21;
  }

  #twofactor .content {
    background: none;
    box-shadow: none;
  }

  #twofactor .content .introduction {
    display: none;
  }

  #twofactor .content .twofactor-content {
    width: 100%;
    box-shadow: 2px 2px 5px black;
    background: #040e21;
    color: #fff;
  }

  #twofactor .content .twofactor-content img {
    display: block;
    margin: 15px 0;
  }

  #twofactor .content .twofactor-content .twofactorForm form input {
    color: #fff;
  }

  #twofactor .content .twofactor-title {
    display: none;
  }
}

.recaptcha {
  margin-top: 15px;
}

.error__captcha {
  color: #fff;
  background: rgb(252, 8, 8);
  padding: 10px;
  border-radius: 30px;
}

.forgot__pass {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.forgot-password-spacing {
  padding: 21px 0px 21px 0;
}