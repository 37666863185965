.assitant-container {
}

.messages {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ajuste a largura conforme necessário */
  overflow: auto;
  height: 490px;
  padding: 15px;
  align-items: center;
}

.message {
  width: 50%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 7px;
}

.message .message-title {
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: bolder;
}

.m-assistant {
  align-self: flex-end;
  border: 1px solid #6259ca;
  background-color: #6259ca;
  color: #fff;
}

.m-user {
  align-self: flex-start;
  border: 1px solid #6259ca;
  background-color: #fff;
  color: #6259ca;
}

.form-message {
  padding: 15px;
}

.form-message form {
  display: flex;
  width: 100%;
}

.form-message form input {
  flex: 0 0 90%;
  margin-right: 2%;
}

.form-message form button {
  flex: 0 0 8%;
  background-color: #6259ca;
  color: #fff;
}

.form-message form button.disabled {
  flex: 0 0 8%;
  background-color: #999;
  color: #fff;
}
