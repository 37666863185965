* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.selectImage input[type="file"] {
  display: none;
}

.selectImage button {
  width: 100%;
  padding: 60px 0;
  min-height: 500px;
  border: none;
  background: #fff;
  border: 1px solid #77a2ff;
  color: #999;
  cursor: pointer;
}

.viewer {
  position: relative;
  overflow: auto;
}

.viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(68, 152, 247);
}

.painel .painel-title a {
  background: #6259ca;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}
