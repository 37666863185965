.titleComp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.titleComp .processo {
  background: #0d4383;
  width: 50%;
  padding: 15px 20px;
  color: #fff;
}
.titleComp .fila {
  background: #7f7f7f;
  width: 50%;
  padding: 15px 20px;
  color: #fff;
}

.titleComp .processo strong,
.titleComp .fila strong {
  margin-right: 7px;
}
